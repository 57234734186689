.get-started-section {
    [id*="_container"] {
        display: inline-block;
        margin: 20px 40px 0px 40px;
    }
    
    @media screen and (max-width: 991px) {
        .shape-divider-wrap .shape-divider {
            height: 75%;
        }
    }
    
    @media screen and (max-width: 767px) {
        .shape-divider-wrap .shape-divider {
            height: 33%;
        }
    }

    .btn {
        padding: 13px 35px;
        font-size: 14px;
        width: 157px;
        font-weight: 600;
        color: $primary;
        border: 2px solid rgba(197, 51, 94, 0.75);
        &:hover {
            border: 2px solid rgba(197, 51, 94, 1);
            color: $primary !important;
            i {
                opacity: 1;
                transform: translateX(45px) rotate(90deg);
            }
            span {
                transform: translate3d(-65%,-50%,0);
            }
        }
        i {
            transition: .3s ease;
            opacity: 0;
            transform: translateX(30px) rotate(90deg);
        }
        span {
            transition: .3s ease;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%,-50%,0);
        }
    }
}